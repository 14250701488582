<script>
// import vue2Dropzone from "vue2-dropzone";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Add Product",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // vueDropzone: vue2Dropzone,
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
        autoClose: "",
      autoClose2: "",
      autoClose3: "",
      autoClose4: "",
      title: "บันทึกแฟ้มประวัติรถ",
      form: {
        fname: "",
        lname: "",
        city: "",
        state: "",
        zipcode: "",
      },
      tooltipform: {
        name: "",
        job: "",
        fname: "",
        lname: "",
        username: "",
        city: "",
        state: "",
        test1: "",
        test2: "",
        test3: "",
        test4: "",
        test5: "",
        test6: "",
        test7: "",
        test8: "",
        test9: "",
        test10: "",
        test11: "",
        test12: "",
        test13: "",
        test14: "",
        test15: "",
        test16: "",
        test17: "",
        test18: "",
        test19: "",
        test20: "",
        test21: "",
        test22: "",
        test23: "",
        test24: "",
        test25: "",
      },
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: "",
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: "",
      },
      options6: [
        "เขียว",
        "ขาว",
        "ดำ",
        "ม่วง",
        "ชมพู",
        "เทา",
      ],
      options3: [
        "เขียว",
        "ขาว",
        "ดำ",
        "ม่วง",
        "ชมพู",
        "เทา",
      ],
      options2: [ 
      {
        id: 1,
        name: "Suzuki",
      },
      {
        id: 2,
        name: "City",
      },
      {
        id: 3,
        name: "Civic",
      }
      ],
      options4: [
        "1500",
        "1800",
        "2000",
        "2200",
        "2400",
        "2600",
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      value: null,
      value2: "",
      value3: null,
      value4: null,
      rows: [],
      dataTest: [],
      items: [
        {
          text: "Home",
        },
        {
          text: "บันทึกแฟ้มประวัติรถ",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
    },
    validations: {
    form: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zipcode: {
        required,
      },
    },
    tooltipform: {
      fname: {
        required,
      },
      lname: {
        required,
      },
      username: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },
    range: {
      minlen: {
        required,
        minLength: minLength(6),
      },
      maxlength: {
        required,
        maxLength: maxLength(6),
      },
      between: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10),
      },
      minval: {
        required,
        minValue: minValue(6),
      },
      maxval: {
        required,
        maxValue: maxValue(6),
      },
      rangeval: {
        required,
        minValue: minValue(6),
        maxValue: maxValue(100),
      },
      expr: {
        required,
      },
    },
    typeform: {
      name: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      email: {
        required,
        email,
      },
      url: {
        required,
        url,
      },
      digit: {
        required,
        numeric,
      },
      number: {
        required,
        numeric,
      },
      alphanum: {
        required,
        alphaNum,
      },
      textarea: {
        required,
      },
    },
  },
  mounted() {
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
  },
    methods: {
    getData: function() {
      this.overlayFlag = true;
      // const bodyFormData = new FormData()
      // bodyFormData.append('name', this.tooltipform.name)
      // bodyFormData.append('job', this.tooltipform.job)
      useNetw
        .get("/api/users", {
        })
        .then((response) => {
          this.rows = response.data.data
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getSelect: function() {
      useNetw
        .get("/api/users", {
        })
        .then((response) => {
          this.dataTest = response.data.data
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
        });
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
        <div class="text-end m-2">
        </div>
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">ข้อมูลรถ</h5>
                    <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form @submit.prevent="tooltipForm" autocomplete="off">
                  <div class="row align-items-end">
                <div class="col-8 col-sm-6 col-md-6">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip03">หมายเลขตัวถัง</label>
                    <input
                      id="validationTooltip03"
                      v-model="tooltipform.city"
                      type="text"
                      class="form-control"
                      placeholder="หมายเลขตัวถัง"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.city.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.city.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.city.required"
                        >Please provide a valid city.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-auto col-sm-12 col-md-6">
                  <div class="mb-3 position-relative">
                    <button class="btn btn-primary m-1" type="submit">
                      Search From CA</button
                    >
                    <button class="btn btn-primary m-1" type="submit">
                      Add From Stock
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-8 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip03">หมายเลขเครื่อง</label>
                    <input
                      id="validationTooltip05"
                      v-model="tooltipform.name"
                      type="text"
                      class="form-control"
                      placeholder="หมายเลขเครื่อง"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.city.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.city.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.city.required"
                        >Please provide a valid city.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-4 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip04">ทะเบียนรถ</label>
                    <input
                      id="validationTooltipz123"
                      v-model="tooltipform.job"
                      type="text"
                      class="form-control"
                      placeholder="ทะเบียนรถ"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.state.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.state.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.state.required"
                        >Please provide a valid state.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">รุ่นรถ</label>
                    <multiselect v-model="value2" label="name" :options="options2" class="helo" :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                      
                      >
                      </multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Model.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">ประเภทรถ</label>
                    <input
                      id="validationTooltip02"
                      v-model="tooltipform.name"
                      type="text"
                      class="form-control"
                      placeholder="ประเภทรถ"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Car type.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername1">ยี่ห้อรถ</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsernamePrepend1"
                        v-model="tooltipform.username"
                        type="text"
                        class="form-control"
                        placeholder="ยี่ห้อรถ"
                        aria-describedby="validationTooltipUsernamePrepend1"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">สีภายนอก</label>
                    <multiselect v-model="value" :options="options6" class="helo" :class="{
                        'is-invalid': submitform && $v.tooltipform.city.$error,
                      }"></multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid Color.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">สีภายใน</label>
                    <multiselect v-model="value3" :options="options3" class="helo" :class="{
                        'is-invalid': submitform && $v.tooltipform.city.$error,
                      }"></multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsernamePrepend2">ระบบเกียร์</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername2"
                        v-model="tooltipform.test1"
                        type="text"
                        class="form-control"
                        placeholder="ระบบเกียร์"
                        aria-describedby="validationTooltipUsernamePrepend2"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">รุ่นปี</label>
                    <input
                      id="validationTooltip08"
                      v-model="tooltipform.test1"
                      type="text"
                      class="form-control"
                      placeholder="รุ่นปี"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsernamePrepend3">ขนาดรถ</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername3"
                        v-model="tooltipform.test2"
                        type="text"
                        class="form-control"
                        placeholder="ขนาดรถ"
                        aria-describedby="validationTooltipUsernamePrepend3"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">Warranty Book No</label>
                    <input
                      id="validationTooltip01"
                      v-model="tooltipform.test3"
                      type="text"
                      class="form-control"
                      placeholder="Warranty Book No"
                      value="Mark"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid First name.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">เลขกุญแจ</label>
                    <input
                      id="validationTooltipp1"
                      v-model="tooltipform.test4"
                      type="text"
                      class="form-control"
                      placeholder="เลขกุญแจ"
                      value="Mark"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid First name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">CC</label>
                    <multiselect v-model="value4" :options="options4" class="helo" :class="{
                        'is-invalid': submitform && $v.tooltipform.city.$error,
                      }"></multiselect>
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername4">Parent Code</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername4"
                        v-model="tooltipform.test5"
                        type="text"
                        class="form-control"
                        placeholder="Parent Code"
                        aria-describedby="validationTooltipUsernamePrepend4"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              </form>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="addproduct-img-collapse"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">ข้อมูลลูกค้า</h5>
                    <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">รหัสผู้ครอบครอง</label>
                    <input
                      id="validationTooltip10"
                      v-model="tooltipform.test6"
                      type="text"
                      class="form-control"
                      placeholder="รหัสผู้ครอบครอง"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid Model.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">ชื่อผู้ครอบครอง</label>
                    <input
                      id="validationTooltipp2"
                      v-model="tooltipform.test7"
                      type="text"
                      class="form-control"
                      placeholder="ชื่อผู้ครอบครอง"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Car type.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-8 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">เบอร์มือถือ</label>
                    <input
                      id="validationTooltip07"
                      v-model="tooltipform.test8"
                      type="text"
                      class="form-control"
                      placeholder="เบอร์มือถือ"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid Color.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">อีเมลลูกค้า</label>
                    <input
                      id="validationTooltipp3"
                      v-model="tooltipform.test9"
                      type="text"
                      class="form-control"
                      placeholder="อีเมลลูกค้า"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">รหัสผู้ใช้รถ</label>
                    <input
                      id="validationTooltip09"
                      v-model="tooltipform.test10"
                      type="text"
                      class="form-control"
                      placeholder="รหัสผู้ใช้รถ"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername5">ชื่อผู้ใช้รถ</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername5"
                        v-model="tooltipform.test11"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อผู้ใช้รถ"
                        aria-describedby="validationTooltipUsernamePrepend5"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">ส่วนลดอะไหล่ %</label>
                    <input
                      id="validationTooltipa1"
                      v-model="tooltipform.test12"
                      type="text"
                      class="form-control"
                      placeholder="ส่วนลดอะไหล่ %"
                      value="Mark"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid First name.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-8 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">เบอร์มือถือ</label>
                    <input
                      id="validationTooltipa2"
                      v-model="tooltipform.test13"
                      type="text"
                      class="form-control"
                      placeholder="เบอร์มือถือ"
                      value="Mark"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid First name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">อีเมลลูกค้า</label>
                    <input
                      id="validationTooltipa3"
                      v-model="tooltipform.test14"
                      type="text"
                      class="form-control"
                      placeholder="อีเมลลูกค้า"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername6">ส่วนลดค่าแรง %</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername6"
                        v-model="tooltipform.test15"
                        type="text"
                        class="form-control"
                        placeholder="ส่วนลดค่าแรง %"
                        aria-describedby="validationTooltipUsernamePrepend6"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-3
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        03
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">ข้อมูลการใช้งานรถ</h5>
                    <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                  <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">วันส่งมอบรถ</label>
                    <date-picker v-model="autoClose" :first-day-of-week="1" lang="en" format="YYYY/MM/DD"></date-picker>
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid Model.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">วันจดทะเบียน</label>
                    <date-picker v-model="autoClose2" :first-day-of-week="1" lang="en" format="YYYY/MM/DD"></date-picker>
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Car type.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">วันหมดประกัน</label>
                    <date-picker v-model="autoClose3" :first-day-of-week="1" lang="en" format="YYYY/MM/DD"></date-picker>
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Car type.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">เข้ารับบริการล่าสุด</label>
                    <input
                      id="validationTooltipb11"
                      v-model="tooltipform.test16"
                      type="text"
                      class="form-control"
                      placeholder="เข้ารับบริการล่าสุด"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid Color.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">เข้ารับบริการก่อนหน้า</label>
                    <input
                      id="validationTooltipz12"
                      v-model="tooltipform.test17"
                      type="text"
                      class="form-control"
                      placeholder="เข้ารับบริการก่อนหน้า"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-8 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">กม หมดประกัน</label>
                    <input
                      id="validationTooltip13"
                      v-model="tooltipform.test18"
                      type="text"
                      class="form-control"
                      placeholder="กม หมดประกัน"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">Invoice No ล่าสุด</label>
                    <input
                      id="validationTooltip14"
                      v-model="tooltipform.test19"
                      type="text"
                      class="form-control"
                      placeholder="Invoice No ล่าสุด"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername7">Invoice No ก่อนหน้า</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsername7"
                        v-model="tooltipform.test20"
                        type="text"
                        class="form-control"
                        placeholder="Invoice No ก่อนหน้า"
                        aria-describedby="validationTooltipUsernamePrepend7"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">เลขที่บัตรส่วนลด</label>
                    <input
                      id="validationTooltipb12"
                      v-model="tooltipform.test20"
                      type="text"
                      class="form-control"
                      placeholder="เลขที่บัตรส่วนลด"
                      value="Mark"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid First name.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip01">เลขกิโลล่าสุด</label>
                    <input
                      id="validationTooltip06"
                      v-model="tooltipform.test21"
                      type="text"
                      class="form-control"
                      placeholder="เลขกิโลล่าสุด"
                      value="Mark"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.fname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.fname.required"
                        >Please provide valid First name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">เลขกิโลก่อนหน้า</label>
                    <input
                      id="validationTooltip15"
                      v-model="tooltipform.test22"
                      type="text"
                      class="form-control"
                      placeholder="เลขกิโลก่อนหน้า"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername">วันหมดอายุ</label>
                    <div class="input-group">
                      <date-picker v-model="autoClose4" :first-day-of-week="1" lang="en" format="YYYY/MM/DD"></date-picker>
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">ระยะทางการใช้งานเฉลี่ย</label>
                    <input
                      id="validationTooltip16"
                      v-model="tooltipform.test23"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltipUsername8">ชื่อ Dealer ที่ขายรถ</label>
                    <div class="input-group">
                      <input
                        id="validationTooltipUsernamePrepend8"
                        v-model="tooltipform.test24"
                        type="text"
                        class="form-control"
                        aria-describedby="validationTooltipUsernamePrepend8"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.username.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.username.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.username.required"
                          >Please choose a unique and valid username.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                </div>
                <div class="col-8 col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="validationTooltip02">ประมาณกิโลเมตร ณ ปัจจุบัน</label>
                    <input
                      id="validationTooltip17"
                      v-model="tooltipform.test25"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6">
                <div class="mb-3 position-relative">
                    <label for="validationTooltip02">หมายเหตุ</label>
                    <textarea v-model="typeform.textarea" class="form-control" :maxlength="225" rows="3" :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }"></textarea>
                    <div
                      v-if="submitform && $v.tooltipform.lname.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.tooltipform.lname.required"
                        >Please provide valid Last name.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                </div>
                <div class="col-6 col-sm-6 col-md-4">
                </div>
              </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a href="#" class="btn btn-danger">
          <i class="uil uil-times me-1"></i> Cancel
        </a>
        <a href="#" class="btn btn-success ms-1" @click="getData">
          <i class="uil uil-file-alt me-1"></i> Save
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
